<template>
  <v-container fluid class="pt-0 fill-height align-start">
    <v-row justify="center" class="fill-height" style="align-content: start">
      <v-col cols="12" md="12" class="pb-2" style="height: 64px">
        <v-toolbar elevation="2" class="pa-0" height="52">
          <v-toolbar-title class="py-2">
            Перелік інструкцій по роботі у платформі WeToo | ВіТу
          </v-toolbar-title>
          <v-spacer/>
        </v-toolbar>
      </v-col>
      <v-col cols="12" class="pb-1">
        <v-card class="px-3 py-2 pb-3">
          <v-text-field v-model="search"
                        hide-details
                        class="pt-0"
                        color="grey"
                        append-icon="mdi-clipboard-text-search-outline"
                        placeholder="введіть текст для пошуку відео"></v-text-field>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-row class="fill-height">
         <v-col cols="12" sm="6" md="3" xl="2" style="gap: 10px" v-for="(item, idx) in filter_items" :key="idx">
           <v-card style="height: 100%">
             <v-card-text class="pb-2">
               <LazyYoutubeVideo
                   :src="item.url"
                   :preview-image-size="item.previewImageSize"
                   :aspect-ratio="item.aspectRatio"
               />
             </v-card-text>
             <v-divider></v-divider>
             <v-card-text class="py-2" style="line-height: 1.09rem; font-weight: 500; font-size: .9rem">
               <div style="display: flex; align-items: center">
                 <div style="flex: 1; padding-right: 4px">
                   {{ item.title }}
                 </div>
                 <div style="flex: 0 0 40px; text-align: right; display: flex">
                   <v-btn small icon class="grey lighten-4 mr-1"
                          :href="item.url_on_chanel"
                          target="_blank"
                   >
                     <v-icon color="error">mdi-youtube</v-icon>
                   </v-btn>
                   <v-menu
                       :close-on-content-click="false"
                       :nudge-width="200"
                       offset-x
                   >
                     <template v-slot:activator="{ on, attrs }">
                       <v-btn small icon class="grey lighten-4"
                              v-bind="attrs"
                              v-on="on"
                       >
                         <v-icon size="20" color="grey darken-1">mdi-information-variant</v-icon>
                       </v-btn>
                     </template>

                     <v-card max-width="340">
                       <v-card-title>
                         {{ item.title }}
                       </v-card-title>
                       <v-card-text>
                         {{ item.info }}
                       </v-card-text>
                     </v-card>
                   </v-menu>
                 </div>
               </div>
             </v-card-text>
           </v-card>
         </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LazyYoutubeVideo from "vue-lazy-youtube-video";

export default {
  name: "Instruction",
  components: {
    LazyYoutubeVideo,
  },
  computed: {
    filter_items() {
      if (!this.search) return this.items

      const search_tags = this.search.split(' ')
      const find = []
      const search_result = {}
      let max_result = 0

      search_tags.forEach(i => {
        const search = i.toLowerCase().trim()

        const items = this.items.filter(s => {
          const tags = s.tags.filter(t => {
            return t.toLowerCase().trim().indexOf(search) !== -1
          })
          return tags.length
        })

        this.items.filter(i => i.info.toLowerCase().trim().indexOf(search) !== -1).forEach(item => {
          items.push(item)
        })

        items.forEach(item => {
          const f = find.find(i => i.url === item.url)
          if (!f) {
            search_result[item.url] = 1
            find.push(item)
            max_result = 1
          } else {
            search_result[item.url] += 1
            if (search_result[item.url] > max_result) {
              max_result = search_result[item.url]
            }
          }
        })
      })

      const urls = []
      Object.keys(search_result).forEach(item => {
        if (search_result[item] === max_result) {
          urls.push(item)
        }
      })
      return find.filter(item => urls.includes(item.url))
    }
  },
  data() {
    return {
      search: '',
      items: [
        {
          url: "https://www.youtube.com/embed/KtKz5C3tFNQ",
          url_on_chanel: 'https://youtu.be/KtKz5C3tFNQ',
          previewImageSize: "hqdefault",
          title: 'Створення послуг',
          info: 'Послуга / стаття тарифу призначена для обліку нарахувань в розрізі цієї одиниці,' +
              'підприємство може самостійно налаштувати перелік послуг/статей тарифу, які воно буде нараховувати ' +
              'абонентам. (членські внески, одноразовий внесок, Вивіз ТПВ, утримання будинку, гараж комірка т ін).',
          tags: [
              'Як створити послугу',
              'Як додати послугу',
              'Як зареєструвати послугу',
              'Створення послуги',
              'Що таке послуга',
              'Навіщо послуга',
          ]
        },
        {
          url: "https://www.youtube.com/embed/8vzhYRNXepc",
          url_on_chanel: 'https://youtu.be/8vzhYRNXepc',
          previewImageSize: "hqdefault",
          title: 'Створення тарифів',
          info: 'В програмі реалізовано два види тарифу: 1. Тариф по організації - охоплює усіх абонентів організації ' +
              'по вказаній групі тарифу, або усіх - якщо вона не вказується. 2. Тариф по будинку - охоплює усіх ' +
              'абонентів будинку в по вказаній групі тарифу, або усіх - якщо вона не вказується. ',
          tags: [
            'Як створити тариф по організації',
            'Як створити тариф по будинку',
            'Як додати тариф по організації',
            'Як додати тариф по будинку',
            'Як зареєструвати тариф по організації',
            'Як зареєструвати тариф по будинку',
            'Створення тарифу по організації',
            'Створення тарифу по будинку',
            'Що таке тариф по організації',
            'Що таке тариф по будинку',
            'Навіщо тариф по організації',
            'Навіщо тариф по будинку',
          ]
        },
        {
          url: "https://www.youtube.com/embed/gU5RWMrFfe8",
          url_on_chanel: 'https://youtu.be/gU5RWMrFfe8',
          previewImageSize: "hqdefault",
          title: 'Створення нормативів',
          info: 'Перелік нормативів - це довідник нормативів для нарахування послуг без лічильника, або' +
              'послуг в яких відмічено "розрахунок по нормативах", ця величина виступає як додаткова величина' +
              'на яку множиться тариф та/або якісний показник (к-сть зареєстрованих/ площа тощо)',
          tags: [
            'Як створити норматив',
            'Як додати норматив',
            'Як зарежєструвати норматив',
            'Створення нормативу',
            'Що таке норматив',
            'Навіщо норматив',
            'пільга по нормативах',
          ]
        },
        {
          url: "https://www.youtube.com/embed/boq7RbZl9LI",
          url_on_chanel: 'https://youtu.be/boq7RbZl9LI',
          previewImageSize: "hqdefault",
          title: 'Реєстрація нормативів',
          info: 'Реєстрація нормативів - процедура вказання актуального нормативу на певну дату, послугу та групу ' +
              'тарифу (за необхідності). Завдяки реєстрації можливе правильне нарахування по нормах та пільг взалежності' +
              'до дати та зміни цього нормативу',
          tags: [
            'Як створити реєстрацію нормативу',
            'Як створити норматив',
            'пільга по нормативах',
            'Як додати реєстрацію нормативу',
            'Як зареєструвати норматив',
            'Створення реєстрації нормативу',
            'Що таке реєастрація нормативу',
            'Навіщо реєструвати норматив',
          ]
        },
        {
          url: "https://www.youtube.com/embed/LFousBkvF38",
          url_on_chanel: 'https://youtu.be/LFousBkvF38',
          previewImageSize: "hqdefault",
          title: 'Створення населених пунктів, вулиць та будинків',
          info: "Геоніми - це термін, який визначає такі одиниці як: населений пункт, вулицю, будинок. Задля" +
              "спрощення інтерфейсу було прийнято рішення об'єднати роботи з цими усіма елементами в одному місці",
          tags: [
            'Як створити населений пункт',
            'Як додати населений пункт',
            'Як зареєструвати населений пункт',
            'Створення населеного пункту',
            'Як створити вулицю',
            'Як додати вулицю',
            'Як зареєструвати вулицю',
            'Створення вулиці',
          ]
        },
        {
          url: "https://www.youtube.com/embed/BO3g2x09X9k",
          url_on_chanel: 'https://youtu.be/BO3g2x09X9k',
          previewImageSize: "hqdefault",
          title: 'Створення абонентів',
          info: "Абонент - це основна одиниця обліку у платформі. Нею може виступати квартира," +
              "приватний будинок, нежитлове приміщення",
          tags: [
            'Як створити абонента',
            'Як додати абонента',
            'Як зареєструвати абонента',
            'Створення абонента',
            'Як створити квартиру',
            'Як додати квартиру',
            'Як зареєструвати квартиру',
            'Створення квартири',
            'Як створити приватний будинок',
            'Як додати приватний будинок',
            'Як зареєструвати приватний будинок',
            'Створення приватного будинку',
          ]
        },
        {
          url: "https://www.youtube.com/embed/iYg4Qil6Fbw",
          url_on_chanel: 'https://youtu.be/iYg4Qil6Fbw',
          previewImageSize: "hqdefault",
          title: 'Створення послуг у абонента',
          info: "Послуги абонента - це перелік послуг/статей тарифу, які надаються абоненту",
          tags: [
            'Як створити послугу у абонента',
            'Як додати послугу у абонента',
            'Як зареєструвати послугу у абонента',
            'Створення послуги абонента',
            'Як створити послугу',
            'Як додати послугу',
            'Як зареєструвати послугу',
            'Створення послуги',
          ]
        },
        {
          url: "https://www.youtube.com/embed/ol3DjIVW7AQ",
          url_on_chanel: 'https://youtu.be/ol3DjIVW7AQ',
          previewImageSize: "hqdefault",
          title: 'Створення/зміна власника помешкання',
          info: "Власник помешкання - особа, яка володіє правом власності на це помешкання",
          tags: [
            'Як створити власника',
            'Як змінити власника',
            'Як додати власника',
            'Як зареєструвати власника',
            'Створення власника',
            'Як створити власника',
            'Як додати власника',
            'Як зареєструвати власника',
            'Створення власника',
            'Зміна власника',
            'Новий власник',
          ]
        },
        {
          url: "https://www.youtube.com/embed/naIpzWcOuqE",
          url_on_chanel: 'https://youtu.be/naIpzWcOuqE',
          previewImageSize: "hqdefault",
          title: 'Створення мешканців (членів сім’ї)',
          info: "Внесення мешканців помешкання дозволить вам видавати довідки та вести поіменний облік мешканців",
          tags: [
            'Як створити мешканця',
            'Як змінити мешканця',
            'Як додати мешканця',
            'Як зареєструвати мешканця',
            'Створення мешканця',
            'Як створити мешканця',
            'Як додати мешканця',
            'Як зареєструвати мешканця',
            'Створення мешканця',
            'Зміна мешканця',
            'Новий мешканець',
          ]
        },
        {
          url: "https://www.youtube.com/embed/xRrPLuW1OYY",
          url_on_chanel: 'https://youtu.be/xRrPLuW1OYY',
          previewImageSize: "hqdefault",
          title: 'Створення пільги (пільгового посвідчення)',
          info: "Пільга - розраховуєтьсяв межах нормативів, для кожної послуги існують власні нормативи. (на утрим. буд.: 10.5 на сім’ю та 21 на кожного пільговика)",
          tags: [
            'Як створити пільгу',
            'Як змінити пільгу',
            'Як додати пільгу',
            'Як додати пільговиків',
            'Як зареєструвати пільгу',
            'Як зареєструвати пільговиків',
            'Створення пільги',
            'Як створити пільгове посвідчення',
            'Як додати пільгове посвідчення',
            'Як зареєструвати пільгове посвідчення',
            'Створення пільгового посвідчення',
            'Зміна пільгового посвідчення',
            'Нове пільгове посвідчення',
          ]
        },
        {
          url: "https://www.youtube.com/embed/gXil2Rt-6GE",
          url_on_chanel: 'https://youtu.be/gXil2Rt-6GE',
          previewImageSize: "hqdefault",
          title: 'Створення лічильника/прилад обліку',
          info: "Лічильник - це прилад обліку об’єму споживання тої чи іншої послуги",
          tags: [
            'Як створити лічильник',
            'Як змінити лічильник',
            'Як додати лічильник',
            'Як зареєструвати лічильник',
            'Створення лічильника',
            'Зміна лічильника',
            'Новий лічильник',
            'Як створити прилад обліку',
            'Як додати прилад обліку',
            'Як зареєструвати прилад обліку',
            'Створення приладу обліку',
            'Зміна приладу обліку',
            'Новий прилад обліку',
          ]
        },
        {
          url: "https://www.youtube.com/embed/ZhXRKuf76_U",
          url_on_chanel: 'https://youtu.be/ZhXRKuf76_U',
          previewImageSize: "hqdefault",
          title: 'Особистий кабінет',
          info: "Особистий кабінет - це віртуальний електронний кабінет мешканця, для деталізації нарахувань, заборгованості та ін.",
          tags: [
            'Як зайти в особистий кабінет',
            'Як зареєструватись в особистому кабінеті',
            'Як додати особистий кабінет',
            'Додати квартиру в особистий кабінет',
          ]
        },
        {
          url: "https://www.youtube.com/embed/1m1u8VJrdc0",
          url_on_chanel: 'https://youtu.be/1m1u8VJrdc0',
          previewImageSize: "hqdefault",
          title: 'Telegram bot',
          info: "Telegram bot - це bot у telegram для зручності перегляду інформації по вашому помешканю",
          tags: [
            'Як зайти в telegram bot',
            'Як зареєструватись в telegram bot',
            'Де знайти telegram bot',
            'Додати telegram bot',
          ]
        },
      ]
    }
  }
}
</script>

<style scoped lang="scss">
tr > td {
  padding: 10px !important;
}
</style>